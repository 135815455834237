import React from "react"
import { Flex } from "@chakra-ui/react"

const ContentWrapper = ({ children }) => {
  return (
    <Flex
      as="section"
      direction={{ base: "column", lg: "row" }}
      justifyContent="space-between"
      mb={8}
      px={{ base: null, md: 4 }}
    >
      {children}
    </Flex>
  )
}

export default ContentWrapper
