import React from "react"
import {
  Box,
  Heading,
  Text,
  Link,
  useDisclosure,
  HStack,
} from "@chakra-ui/react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Lightbox } from "../../components"
import CategorySwitch from "../../../config/svgs"

const ArticleHeader = ({
  title,
  pageType,
  fixeElementeTop,
  weiterfuehrendeLinksUndDownloads,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isBlickePage = pageType === "BLICKE"

  const keys = Object.keys(weiterfuehrendeLinksUndDownloads)
    .map(key => weiterfuehrendeLinksUndDownloads[key] && key)
    .filter(e => e !== null)

  const breadcrumbs = keys.length > 0

  const image = fixeElementeTop.teaserBild

  const teaserImage =
    fixeElementeTop?.teaserBild?.bild && getImage(image.bild.localFile)

  return (
    <Box as="section" py={12} px={{ base: null, md: 4 }}>
      <Box maxW="800px">
        {pageType && (
          <Box mb={isBlickePage ? 4 : 12} ml={{ base: 0, lg: "-10px" }}>
            <HStack mb={breadcrumbs ? 2 : 4} spacing={6} ml="-3px">
              {fixeElementeTop.kategorie &&
                fixeElementeTop.kategorie.map((cat, index) => (
                  <HStack align={"flex-start"} key={index}>
                    <Box
                      display="inline-box"
                      w={5}
                      mb={"0.2rem"}
                      flexShrink={0}
                    >
                      {CategorySwitch(cat)}
                    </Box>
                    <Text
                      pt="3px"
                      as="span"
                      fontFamily="CocoSharp-Bold"
                      lineHeight="1rem"
                    >
                      {cat}
                    </Text>
                  </HStack>
                ))}
            </HStack>
            {breadcrumbs && (
              <Box pos="relative" pl="20px">
                <Text fontSize="0.9375rem" fontFamily="CocoSharpL-Regular">
                  {keys.map((key, index) => {
                    const PageLink = ({ href, title, index }) => (
                      <>
                        {index > 0 && <> / </>}
                        <Link href={`#${href}`}>{title}</Link>
                      </>
                    )

                    switch (key) {
                      case "kontakt":
                        return (
                          <PageLink
                            key={key}
                            href="kontakt"
                            title="Kontakt"
                            index={index}
                          />
                        )
                      case "weiterfuehrendeLinks":
                        return (
                          <PageLink
                            key={key}
                            href="weiterfuehrende-links"
                            title="Weiterführende Links"
                            index={index}
                          />
                        )
                      case "downloadsBottom":
                        return (
                          <PageLink
                            key={key}
                            href="downloads"
                            title="Downloads"
                            index={index}
                          />
                        )

                      default:
                        return null
                    }
                  })}
                </Text>
                <Box position="absolute" left={0} top="3px">
                  <ScrollIcon />
                </Box>
              </Box>
            )}
          </Box>
        )}
        <Heading
          as="h1"
          fontSize={{
            base: isBlickePage ? "2.125rem" : "1.875rem",
            sm: isBlickePage ? "3.75rem" : "3.4375rem",
          }}
          color="dark_blue"
          fontFamily={isBlickePage ? "kepler-std-display" : "CocoSharp-Bold"}
          fontWeight={isBlickePage && "semibold"}
          letterSpacing="0.12px"
          lineHeight={{
            base: isBlickePage ? "2.125rem" : "1.875rem",
            sm: isBlickePage ? "3.4375rem" : "3.75rem",
          }}
          mb={6}
          maxWidth="800px"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {fixeElementeTop?.teaserText && (
          <>
            <Text
              fontFamily="CocoSharpL-Regular"
              fontSize="1.25rem"
              lineHeight="1.7rem"
              mb={8}
              dangerouslySetInnerHTML={{ __html: fixeElementeTop.teaserText }}
            />
          </>
        )}
      </Box>
      {teaserImage && (
        <>
          <Box
            cursor={image.oeffnenInLightbox && "pointer"}
            onClick={image.oeffnenInLightbox && onOpen}
          >
            <GatsbyImage
              image={teaserImage}
              alt={image.bild?.altText || title}
            />
            <Box maxW={{ xl: "calc(900px - 7rem)" }}>
              {image.bildtext && (
                <Text
                  fontFamily="CocoSharpL-Bold"
                  fontSize="0.9735rem"
                  lineHeight="1.25rem"
                  mt={4}
                >
                  {image.bildtext}
                </Text>
              )}
              {image.bildquelle && (
                <Text
                  fontFamily="CocoSharpL-Regular"
                  fontSize="0.9735rem"
                  lineHeight="1.25rem"
                  mt={!image.bildtext ? 4 : 3}
                >
                  Quelle: {image.bildquelle}
                </Text>
              )}
            </Box>
          </Box>
          {image.oeffnenInLightbox && (
            <Lightbox isOpen={isOpen} onClose={onClose}>
              <GatsbyImage
                image={teaserImage}
                alt={image.bild?.altText || image.bild?.title}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </Lightbox>
          )}
        </>
      )}
    </Box>
  )
}

export default ArticleHeader

export const query = graphql`
  fragment PageTemplateElementeTopFragment on WpPage_Pagebuilder {
    fixeElementeTop {
      kategorie
      teaserText
      typ {
        name
      }
      teaserBild {
        bild {
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        bildtext
        bildquelle
        oeffnenInLightbox
      }
    }
  }
`

export const ScrollIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.694"
    height="11.065"
    viewBox="0 0 11.694 11.065"
  >
    <g
      id="Group_1668"
      data-name="Group 1668"
      transform="translate(11.34) rotate(90)"
    >
      <line
        id="Linie_79"
        data-name="Linie 79"
        x1="10.358"
        transform="translate(0 5.493)"
        fill="none"
        stroke="#002149"
        strokeWidth="1"
      />
      <path
        id="Pfad_1589"
        data-name="Pfad 1589"
        d="M437.646,417.556l5.493,5.493-5.493,5.493"
        transform="translate(-432.781 -417.556)"
        fill="none"
        stroke="#002149"
        strokeWidth="1"
      />
    </g>
  </svg>
)
