import React from "react"

import { Box } from "@chakra-ui/react"

const Background = () => {
  return (
    <Box
      bg="#EDF5F7"
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      minWidth={{ lg: "850px" }}
      width={{ base: "100vw", lg: "68vw" }}
      zIndex={-2}
    />
  )
}

export default Background
