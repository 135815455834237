import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Box, Text, Heading, Stack, VStack, Link } from "@chakra-ui/react"
import { graphql, Link as GatsbyLink } from "gatsby"
import { BlickeReiterSvg } from "../../layout/svgs"

const WeitereBlicke = ({ data }) => {
  if (!data) return null

  return (
    <>
      <Box as="section" mt={8} mb={16}>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 12, md: 4 }}
          flexWrap={{ base: "wrap", md: null }}
          alignItems={{ base: null, md: "flex-start" }}
        >
          {data.map((item, index) => {
            const { id, title, uri, featuredImage } = item
            const { teaserText } = item.pagebuilder.fixeElementeTop
            const image =
              featuredImage?.node.localFile &&
              getImage(featuredImage.node.localFile)
            return (
              <Box
                flex={1}
                position="relative"
                bg="white"
                borderWidth="1px"
                borderColor="dark_blue"
                maxWidth={{ base: null, md: "50%", lg: "33%" }}
                key={id}
              >
                <VStack height="100%" pt={8}>
                  <Box px={4}>
                    {title && (
                      <>
                        <Heading
                          fontFamily="kepler-std"
                          fontSize="1.875rem"
                          lineHeight="1.875rem"
                          fontWeight="semibold"
                          textAlign="center"
                        >
                          {title}
                        </Heading>

                        <div
                          style={{
                            width: "15px",
                            margin: "1rem auto",
                            borderBottom: "1px solid black",
                          }}
                        />
                      </>
                    )}
                    {teaserText && (
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: teaserText,
                        }}
                        lineHeight="1.3125rem"
                        textAlign="center"
                        pb={4}
                      />
                    )}
                  </Box>
                  {image && (
                    <Box width="100%">
                      <GatsbyImage
                        image={image}
                        alt={
                          featuredImage?.node.altText ||
                          featuredImage?.node.title
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        objectFit="cover"
                      />
                    </Box>
                  )}
                  <Link
                    to={uri}
                    as={GatsbyLink}
                    _hover={{ textDecoration: "none" }}
                    role="group"
                    mt="0!important"
                    aria-label={`Hier ist der Link zu "${title}"`}
                  >
                    <Box
                      width="170px"
                      height="50px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      border="1px"
                      borderColor="#212121"
                      borderRadius="32px"
                      backgroundColor="white"
                      _hover={{ backgroundColor: "#E9C542" }}
                      mx="auto"
                      mt="-25px"
                      position="relative"
                      zIndex="2"
                      mb="-26px"
                    >
                      <Text
                        fontFamily="CocoSharpL-Bold"
                        textAlign="center"
                        fontSize="0.875rem"
                        letterSpacing="1px"
                        fontWeight="bold"
                        pt={1}
                      >
                        ARTIKEL LESEN
                      </Text>
                    </Box>
                  </Link>
                </VStack>
                <Box
                  width="100px"
                  height="32px"
                  position="absolute"
                  display={{
                    base: index === 0 ? "flex" : "none",
                    md: index === 1 || data.length === 1 ? "flex" : "none",
                  }}
                  justifyContent="center"
                  alignItems="center"
                  top="-32px"
                  left="50%"
                  transform="auto"
                  translateX={"-50%"}
                >
                  <BlickeReiterSvg />
                </Box>
              </Box>
            )
          })}
        </Stack>
      </Box>
    </>
  )
}

export default WeitereBlicke

export const query = graphql`
  fragment PageTemplateWeitereBlicke on WpPage_Pagebuilder {
    eingabe {
      ... on WpPage {
        id
        uri
        title
        featuredImage {
          node {
            title
            altText
            localFile {
              name
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        pagebuilder {
          fixeElementeTop {
            teaserText
          }
        }
      }
    }
  }
`
