import React from "react"
import { Box } from "@chakra-ui/react"
import { graphql } from "gatsby"

import { FeaturedArticle, Accordion } from "../../components"

const Sidebar = ({ data }) => {
  return (
    <Box mx={{ base: null, lg: 4 }} w={{ lg: "300px" }} flex={1}>
      {data?.map((item, index) => {
        switch (item.fieldGroupName) {
          case "Page_Pagebuilder_SidebarElements_Seite":
            return item.seite ? (
              <FeaturedArticle
                key={item.fieldGroupName + index}
                data={item.seite}
              />
            ) : null
          case "Page_Pagebuilder_SidebarElements_TogglBox":
            return <Accordion key={item.fieldGroupName + index} data={item} />
          default:
            return null
        }
      })}
    </Box>
  )
}

export default Sidebar

export const query = graphql`
  fragment PageTemplateSidebarFragment on WpPage_Pagebuilder {
    sidebarElements {
      ... on WpPage_Pagebuilder_SidebarElements_Seite {
        fieldGroupName
        seite {
          ... on WpPage {
            title
            uri
            pagebuilder {
              fixeElementeTop {
                kategorie
                typ {
                  name
                }
                teaserText
                teaserBild {
                  bildquelle
                  bild {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      ... on WpPage_Pagebuilder_SidebarElements_TogglBox {
        fieldGroupName
        ueberschrift
        aufklappbareDatenOderProjekte {
          fieldGroupName
          projekteUndDaten {
            ... on WpPage {
              id
              title
              uri
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              pagebuilder {
                fixeElementeTop {
                  teaserText
                }
              }
            }
          }
        }
      }
    }
  }
`
